.Breadcrumbs {
    display  : flex;
    padding  : 0px 20px;
    display  : flex;
    font-size: 24px;
}

.Breadcrumbs .title {
    display        : flex;
    justify-content: center;
    align-self     : center;
    color          : rgb(94, 178, 225);
    padding        : 10px;
}

.Breadcrumbs .black {
    color: #000
}

.Breadcrumbs .arrow {
    display        : flex;
    justify-content: center;
    align-self     : center;
    color          : black;
}