.info {
    display        : flex;
    justify-content: center;
    align-items    : center;
    flex-direction : column;
    padding        : 4px;
}

.info>* {
    margin: 2px 0
}

.errorBg {
    background: rgba(255, 0, 0, 0.1);
    padding   : 4px
}