.ApproverContainer {
    display       : flex;
    flex-direction: column;
}

.ApproverContainer .filterWrapper {
    display : flex;
    width   : 100%;
    position: relative;
}

.ApproverContainer .filterWrapper .ApproverFilter {
    left: 10px !important;
    top : 25px;
}

.ApproverContainer .filterWrapper .filterValuesContainer {
    display  : flex;
    flex-wrap: wrap;
    max-width: 1100px;
}

.ApproverContainer .filterWrapper .filterValuesContainer .badge {
    margin: 4px;
}