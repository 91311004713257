.GT_Description {
    display        : flex;
    justify-content: center;
    align-items    : center;
}

.GT_Description .description {
    padding: 0 10px;
}

.GT_Description .errorBg {
    background: rgba(255, 0, 0, 0.1);
    padding   : 4px
}