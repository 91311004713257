.list_container {
    width : 20%;
    height: 100%;
}

.list_container .listContainer {
    height: 95.6%;
}

.map_container {
    width: 81%;
}

.areas_manager {
    display: flex;
    height : 100%;
}