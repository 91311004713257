.Report {
    display       : flex;
    flex-direction: column;
}

.Report .UpperTablesContainer {
    display: flex;
    margin : 20px 0;
}

.Report .UpperTablesContainer .UpperTables {
    /* display: flex; */
    flex  : 1;
    margin: 0 50px;
}

.Report .UpperTablesContainer .UpperTables .tableTitle {
    display        : flex;
    justify-content: center;
    align-items    : center;
    padding        : 0 0 10px;
    font-size      : 16px;
    font-weight    : bolder;
}