.GT_ShapeColor {
    display        : flex;
    justify-content: center;
    align-items    : center;
}

.ColorButton {
    display        : flex;
    justify-content: space-between;
    align-items    : center;
    width          : 100%;
}

.GT_ShapeColor > div {
    width: 47%;
} 

.GT_ShapeColor .dropdown button {
    height: 22px;
}