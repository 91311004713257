.ApproverFilter {
    position        : absolute;
    width           : 506px;
    height          : 405px;
    background-color: #deeff9;
    z-index         : 1;
    border          : 1px solid #d8dfe5;
    left            : 408px;
    padding         : 20px;
    display         : flex;
    flex-direction  : column;
}

.ApproverFilter .mainTitle {
    font-size : 18px;
    text-align: center;
}


.ApproverFilter .filterOptions {
    display       : flex;
    flex-direction: column;
}

.ApproverFilter .filterOptions .section {
    display    : flex;
    align-items: center;
    padding    : 5px 0px;
}

.ApproverFilter .filterOptions .section>label {
    width: 130px;
}

.ApproverFilter .filterOptions .section>input[type="text"] {
    width : 300px;
    border: 0;
}

.ApproverFilter .filterOptions .section>input[type="text"]:focus {
    outline: none;
}

.ApproverFilter .filterOptions .section>input[type="checkbox"] {
    width   : 17px;
    height  : 17px;
    padding : 0;
    margin  : 0;
    border  : 0;
    position: relative;
    bottom  : 2px;
}

.ApproverFilter .filterOptions .section #sentBackBy {
    width      : 255px;
    border     : 0;
    margin-left: 10px
}

.ApproverFilter .filterOptions .actionBtns {
    display        : flex;
    justify-content: center;
    align-items    : center;
    padding-top    : 60px;
}

.ApproverFilter .filterOptions .actionBtns>button {
    margin: 0 10px;
}




.ApproverFilter .sortPickerWrapper .SortPicker .customDatesPickerWrapper {
    position: absolute;
    left    : -417px;
    top     : 31px;
    width   : 590px;
}

.ApproverFilter .sortPickerWrapper .SortPicker .customDatesPickerWrapper .DatesRangePicker>div>div:nth-child(3) {
    position: absolute;
    top     : 44px;
    left    : 25px;
}

.ApproverFilter .sortPickerWrapper .SortPicker .customDatesPickerWrapper .DatesRangePicker div:nth-child(3)>button {
    visibility: hidden;
}