.leaderboardPage {
    display       : flex;
    height        : 100%;
    flex-direction: column;
}

.leaderboardPage .tablesWrapper {
    display: flex;
}

.leaderboardPage .tablesWrapper {
    display: flex;
}

.leaderboardPage .tablesWrapper .paintersTableWrapper,
.leaderboardPage .tablesWrapper .supervisorsTableWrapper {
    display       : flex;
    flex          : 1;
    flex-direction: column;
}