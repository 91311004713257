.listItemContainer {
    display      : flex;
    border-bottom: 1px solid #b2b2b2;
    padding      : 8px;
    min-height   : 120px;
    margin-bottom: 10px;
}

.listItemContainer .centerWrapper {
    display       : flex;
    flex-direction: column;
    flex          : 1;
}

.listItemContainer .checkboxWrapper {
    display        : flex;
    justify-content: center;
    align-items    : center;
    padding-right  : 7px;
}

.listItemContainer .checkboxWrapper input[type="checkbox"] {
    width : 15px;
    height: 15px;
}

.listItemContainer.multipleDeleteActive {
    background-color: #ff000036;
}

.listItemContainer.active {
    background-color: #d0d7e7;
}