/* Container */
.NavigationBar{
    display:flex;
    flex-direction: row;
    align-items:baseline;
    background-color: #52525236;
}

/* item */
.navigationBtnsWrapper{
    flex-grow: 100;
    display:flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items:baseline;

}

/* item */
.locationLabel{
    flex-grow: 1;
    
}