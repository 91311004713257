.itemActionsContainer {
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-content  : flex-end;
    padding-left   : 8px;
}

.itemActionsContainer .btn {
    display        : flex;
    justify-content: center;
    align-items    : center;
    width          : 27px;
    height         : 27px;
    margin-bottom  : 5px;
}