.listItemRow {
    padding-bottom: 5px;
    display:flex;
}

.listItemRow .keyLabel {
    font-weight : bold;
    margin-right: 10px;
    color       : #262626
}

.listItemRow .value {
    color: #4c4c4c;
}   