.ApproveCommentBox {
    display       : flex;
    flex-direction: column;
    width         : 100%
}

.ApproveCommentBox>textarea {
    width : 435px;
    height: 60px;

}