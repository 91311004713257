.QuestionToApprove {
    display        : flex;
    justify-content: space-between;
    align-items    : center;
    padding        : 5px 0;
    width          : 500px;
}

.QuestionToApprove .question {
    display  : flex;
    font-size: 15px;
}

.QuestionToApprove .answerOptions {
    display: flex;
}