.scoresWrapper {
    justify-content: flex-end;
}

.scoresWrapper .tableRowSection {
    display: flex;
    margin : 0 15px;
}

.scoresWrapper .tableRowSection>div {
    min-width: 100px;
    padding  : 6px;
}