.supervise_table_container{
    padding: 10px;
    /* overflow-y: scroll; */
    width: 40%;
}
.supervise_map_container{
    padding: 10px;
    width:60%;
}

.supervise_manager{
    padding:12px;
    display: flex;
    height: 93%;
}

.table>tbody>tr>td{
    vertical-align: middle;
}



.painter_result_info_div{
    padding:3px 0 3px 0
}