.AreasSettingsModal {
    display       : flex;
    flex-direction: column;
    padding       : 20px;
    flex          : 1;
    align-items   : flex-start;
    height        : 100%;
    color         : #000;

}

.AreasSettingsModal .section {
    display    : flex;
    align-items: center;

}

.AreasSettingsModal .section .sectionLabel {
    padding-right: 40px;

}