.addEdit_div .separator {
    margin       : 10px 0;
    border-bottom: 1px solid #a6a6a6;
}

.addEdit_div .MultilevelPickerWrapper {
    display       : flex;
    flex-direction: row;
}

.addEdit_div .actionBtns {
    display        : flex;
    justify-content: space-between;

}

.addEdit_div .actionBtns button {
    width: 100px
}

.addEdit_div .MultilevelPickerWrapper .required {
    color   : red;
    position: relative;
    top     : 5px;
}