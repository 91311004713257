.Approve {
    display       : flex;
    flex          : 1;
    height        : 100%;
    width         : 100%;
    flex-direction: column;
}

.Approve .StagesContainer {
    position: relative;
    display : flex;
    height  : 100%;
    width   : 100%;
}

.stage {
    display: flex;
    flex   : 1;
    height : 100%;
    width  : 100%;
    padding: 10px;
}

.stage .leftContainer {
    display       : flex;
    flex          : 1;
    padding       : 0 10px;
    flex-direction: column;
}

.stage .leftContainer .tableWrapper {
    display       : flex;
    flex-direction: column;
    padding       : 2px;
    height        : 504px;
    max-height    : 504px;
    overflow-y    : auto;
}

.stage .leftContainer .tableWrapper .tableTitle .badge {
    margin-left: 5px;
}

.stage .leftContainer .tableWrapper .showHideContainer {
    display    : flex;
    align-items: center;
}

.stage .leftContainer .tableWrapper .showHideContainer span {
    font-size: 13px;
    color    : rgb(94, 178, 225);
    padding  : 0 10px 0 0;
    cursor   : pointer;
}

.stage .leftContainer .tableWrapper .tableTitle {
    display        : flex;
    justify-content: center;
    align-items    : center;
    padding        : 0 0 10px;
    font-size      : 20px;
    font-weight    : bolder;
}

.stage .mapWrapper {
    display: flex;
    flex   : 1;
}




.stage .leftContainer .bottomWrapper {
    display          : flex;
    flex-direction   : column;
    /* height        : 263px; */
}

.stage .leftContainer .bottomWrapper .actionBtns {
    position       : relative;
    display        : flex;
    padding-top    : 20px;
    justify-content: center;
}

.stage .leftContainer .bottomWrapper .actionBtns .validationErrorMessage::before {
    content: "*";
}

.stage .leftContainer .bottomWrapper .actionBtns .validationErrorMessage {
    position   : absolute;
    top        : 20px;
    left       : 0;
    display    : flex;
    color      : red;
    font-weight: bold;
    width      : 385px;
    font-size  : 12px;
}

.stage .leftContainer .bottomWrapper .actionBtns button {
    margin: 0 5px;
}

.stage .leftContainer .bottomWrapper .questionsWrapper {
    display       : flex;
    flex-direction: column;
    margin-bottom : 20px;
}

.stage .leftContainer .bottomWrapper .commentsWrapper {
    display        : flex;
    justify-content: space-between;
}

.stage .leftContainer .bottomWrapper .commentsWrapper>* {
    flex: 1;
}

.stage .leftContainer .bottomWrapper .commentsWrapper .supervisorsAnswerWrapper {
    display       : flex;
    flex-direction: column;
}

.stage .leftContainer .bottomWrapper .commentsWrapper .supervisorsAnswerWrapper textarea {
    height: 60px;
    width : 435px;
}

.Approve .filterValuesContainer {
    display     : flex;
    flex-wrap   : wrap;
    /* max-width: 1100px; */
    flex        : 1;
}

.Approve .filterValuesContainer .badge {
    margin: 4px;
}