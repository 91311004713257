.task_list_container{
    
    width: 20%;
    /* border:red solid 1px */
}
.task_map_container{
    width:80%;
    /* border:green solid 1px */
}

.task_manager{
    display: flex;
    height: 100%;
}