.AreasFilterByTasks .filterByTasksWrapper {
    position: relative;
}

.AreasFilterByTasks .filterbyTaskBar {
    display         : flex;
    justify-content : space-between;
    align-items     : center;
    background-color: #5eb2e1;
    cursor          : pointer;
    color           : #FFF;
    padding         : 5px 10px;
    margin-bottom   : 5px;
}

.AreasFilterByTasks .filterbyTaskBar .titleWrapper {
    width          : 137px;
    display        : flex;
    justify-content: space-between;
    align-items    : center;
}

.AreasFilterByTasks .filterbyTaskBar .rightWrapper {
    width          : 50px;
    display        : flex;
    justify-content: space-between;

}

.AreasFilterByTasks .filterByTasksContainer {
    display         : flex;
    flex-direction  : column;
    position        : absolute;
    top             : 0;
    left            : 0;
    height          : 450px;
    background-color: rgba(238, 247, 252, 0.96);
    width           : 100%;
    z-index         : 1;
}

.AreasFilterByTasks .filterByTasksContainer .list {
    overflow-y: auto;
    height    : 390px;
}

.AreasFilterByTasks .filterByTasksContainer .actionBtns {
    display        : flex;
    flex           : 1;
    width          : 100%;
    padding        : 10px;
    align-items    : center;
    justify-content: center;
}

.AreasFilterByTasks .filterByTasksContainer .actionBtns {
    align-self: flex-end;
}

.AreasFilterByTasks .filterByTasksContainer .actionBtns button {
    margin: 0 10px
}

.AreasFilterByTasks .filterByTasksContainer .filterListItem {
    min-height   : 25px;
    padding      : 5px;
    display      : flex;
    align-items  : center;
    font-size    : 12px;
    margin-bottom: 1px;
    cursor       : pointer;
}

.AreasFilterByTasks .filterByTasksContainer .filterListItem.selected {
    background: #bee0f3
}